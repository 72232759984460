<!--退款费用 modal-->
<template>
  <Modal class-name="i-modal" v-model="isShow" title="退款费用" width="600">
    <Form :label-width="80">
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="手续费:">
            <Input v-model="formData.handleCost" @on-blur="handleCalculate" />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="物流费:">
            <Input
              v-model="formData.logisticsCost"
              @on-blur="handleCalculate"
            />
          </FormItem>
        </Col>
      </Row>
      <Row :gutter="16">
        <Col span="12">
          <FormItem label="可退金额:">
            <Input v-model="formData.CanRefund" disabled />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem label="实退金额:">
            <Input v-model="formData.refundAmount" />
          </FormItem>
        </Col>
      </Row>
      <FormItem label="备注:">
        <Input
          type="textarea"
          v-model="formData.remark"
          placeholder="请输入备注"
        />
      </FormItem>
    </Form>
    <div slot="footer">
      <Poptip
        confirm
        title="您确认要退款吗？"
        :disabled="loading"
        :loading="loading"
        @on-ok="handleRefund"
      >
        <Button :loading="loading" type="primary">退款</Button>
      </Poptip>
      <Button class="ml8" @click="isShow = false">取消</Button>
    </div>
  </Modal>
</template>

<script>
import api from "@/api";
export default {
  name: "refund-modal",
  data() {
    return {
      isShow: false,
      loading: false,
      formData: {
        payLogID: "",
        logisticsCost: "",
        handleCost: "",
        refundAmount: "",
        CanRefund: "",
        remark: "",
      },
    };
  },
  methods: {
    async handleRefund() {
      try {
        if (this.loading) {
          return false;
        }
        this.loading = true;
        await api.RefundPayLog(this.formData);
        this.$Message.success("支付记录退款成功");
        this.isShow = false;
        this.$emit("on-success", this.formData);
        this.loading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.loading = false;
      }
    },
    handleCalculate() {
      const result =
        this.$big.minus(
          this.formData.CanRefund,
          this.$big.plus(this.formData.handleCost, this.formData.logisticsCost)
        ) * 1;
      if (result < 0) {
        this.$Message.error("手续费和物流费之和不得大于支付金额!");
      } else {
        this.formData.refundAmount = result;
      }
    },
  },
};
</script>

<style lang="less"></style>
